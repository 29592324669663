import React from "react";
import MainContent from "../Currency/MainContent";

const CurrencyPage = () => {
    return <>
    <MainContent/>
    </>;
};

export default CurrencyPage;
